const actions = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  FETCH_TOKEN_REQUEST: 'FETCH_TOKEN_REQUEST',
  FETCH_TOKEN_SUCCESS: 'FETCH_TOKEN_SUCCESS',
  GOOGLE_REDIRECT_REQUEST: "GOOGLE_REDIRECT_REQUEST",
  GOOGLE_REDIRECT_SUCCESS: "GOOGLE_REDIRECT_SUCCESS",
  GOOGLE_GET_DATA_REQUEST: "GOOGLE_GET_DATA_REQUEST",
  GOOGLE_GET_DATA_SUCCESS: "GOOGLE_GET_DATA_SUCCESS",

  login: (payload: any) => ({
    type: actions.LOGIN_REQUEST,
    payload,
  }),
  logout: () => ({
    type: actions.LOGOUT_REQUEST,
  }),
  redirectGoogle: (payload: any) => ({
    type: actions.GOOGLE_REDIRECT_REQUEST,
    payload
  }),
  getGoogleData: (payload: any) => ({
    type: actions.GOOGLE_GET_DATA_REQUEST,
    payload
  }),
  updateProfile: (payload: any) => ({
    type: actions.FETCH_TOKEN_REQUEST,
    payload
  })

};
export default actions;
