import styled from 'styled-components';
import theme from '../../config/themes/light'

export const Divider = styled.div`
  background-color: ${(props) => theme.palette.secondary.color};
  height: 4px;
  border-radius: 16px;
  width: 100px;
  margin: 16px 0 0 0;
`;

export const RememberGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const WelcomeText = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
