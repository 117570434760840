import { Button, Grid, Typography } from '@l_ine/core';
import React from 'react';
import { connect } from 'react-redux';
import { Layout } from '../../components';
import Api from '../../helpers/api';
import AppActions from '../../redux/app/actions';
import AuthActions from '../../redux/auth/actions';
import { SignInProps } from './index.d';
import * as styled from './index.style';

const banner = 'static/svgs/loginBanner.svg';

const handleLogin = async () => {
  const redirect: { url: string } = await Api.googleAuth();

  const windowArea = {
    width: Math.floor(window.outerWidth * 0.3),
    height: Math.floor(window.outerHeight * 0.7),
    left: 0,
    top: 0,
  };

  windowArea.left = Math.floor(
    window.screenX + (window.outerWidth - windowArea.width) / 2
  );
  windowArea.top = Math.floor(
    window.screenY + (window.outerHeight - windowArea.height) / 2
  );
  const windowOpts = `toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,
  width=${windowArea.width},height=${windowArea.height},
  left=${windowArea.left},top=${windowArea.top}`;

  const authWindow = window.open(redirect.url, 'producthuntPopup', windowOpts);
};

const Form = () => {
  return (
    <>
      <Grid item xs={12}>
        <styled.WelcomeText>
          <Typography variant='titleLG'>Seja bem-vindo!</Typography>
          <styled.Divider />
        </styled.WelcomeText>
      </Grid>
      <Grid item xs={12} style={{ padding: '16px' }}>
        <Button
          data-testid='login--button-signin'
          size={'lg'}
          onClick={handleLogin}
          variant='contained'
          bgColor='primary'
          fullWidth={true}
        >
          Acessar
        </Button>
      </Grid>
    </>
  );
};

const SignIn: React.FC<SignInProps> = (props) => {
  const onSubmit = async (values: any) => {
    if (values) {
      props.login(values);
    }
  };

  return (
    <Layout
      bannerSrc={banner}
      bannerAlt='Homem com aspecto feliz'
      form={<Form />}
      onSubmit={onSubmit}
    />
  );
};

const { login } = AuthActions;
const { toggleSnackBar } = AppActions;

export default connect(null, { login, toggleSnackBar })(SignIn);
